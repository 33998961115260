<script setup lang="ts">
import { useClientStore } from '../../stores/ClientStore.ts';
import { computed, onMounted, onUnmounted } from 'vue';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '../../components/ui/card';
import { Clock, Globe } from 'lucide-vue-next';
import HotkeyIndicator from '../../components/hotkey/HotkeyIndicator.vue';
import { Hotkey } from '../../types/Hotkey.ts';
import { CircleX } from 'lucide-vue-next';
import Loader from '../../components/Loader.vue';
import { Button } from '../../components/ui/button';
import { resetAction } from '../../actions/app/ResetAction.ts';
import { useRoute } from 'vue-router';
import { selectAlgorithmAction } from '../../actions/solve/SelectAlgorithmAction.ts';
import { generateAlgorithmStreamId } from '../../ws/id/GenerateAlgorithmStreamId.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { clampNumber } from '../../utils/AppUtil.ts';
import { router, ROUTES } from '../../router.ts';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import { useHostStore } from '../../stores/HostStore.ts';
import { useUiStore } from '../../stores/UiStore.ts';

const route = useRoute();

const hostStore = useHostStore();
const clientStore = useClientStore();
const uiStore = useUiStore();

const selectAlgorithm = async (algorithmId: string) => {
    await selectAlgorithmAction(String(route.params.solveId), algorithmId);
};

const onHotkeyPress = (hotkey: Hotkey) => {
    let curSelection: number;
    if (String(route.params.solveId) in clientStore.navigationVerticalIndexes) {
        curSelection = Number(clientStore.navigationVerticalIndexes[String(route.params.solveId)]);
    } else {
        curSelection = 0;
    }

    let nextSelection = curSelection;
    if (hotkey === Hotkey.Down) {
        nextSelection = clampNumber(curSelection + 1, 0, loadedAlgorithmsList.value.length - 1);
        clientStore.navigationVerticalIndexes[String(route.params.solveId)] = nextSelection;
    } else if (hotkey === Hotkey.Up) {
        nextSelection = clampNumber(curSelection - 1, 0, loadedAlgorithmsList.value.length - 1);
        clientStore.navigationVerticalIndexes[String(route.params.solveId)] = nextSelection;
    } else if (hotkey === Hotkey.Right) {
        const algorithm = loadedAlgorithmsList.value[curSelection];
        if (!algorithm) {
            console.error('Unable to select algorithm');
            return;
        }

        selectAlgorithm(algorithm.id);
    } else if (hotkey === Hotkey.Left) {
        router.push(ROUTES.index);
    }
};

const reset = () => {
    resetAction(true);
};

const selectedAlgoIndex = computed(() => {
    if (
        typeof clientStore.navigationVerticalIndexes[String(route.params.solveId)] !== 'undefined'
    ) {
        return clientStore.navigationVerticalIndexes[String(route.params.solveId)];
    }

    return 0;
});

const loadedAlgorithmsList = computed(() => {
    return Object.values(clientStore.solveAlgorithms);
});

const isStreamLoading = computed(() => {
    const algorithmStreamId = generateAlgorithmStreamId(String(route.params.solveId));

    return (
        clientStore.streamsWaiting.includes(algorithmStreamId) ||
        clientStore.streamsInProgress.includes(algorithmStreamId)
    );
});

const isStreamErrored = computed(() => {
    const algorithmStreamId = generateAlgorithmStreamId(String(route.params.solveId));

    return clientStore.streamsErrored.includes(algorithmStreamId);
});

onMounted(() => {
    eventEmitterUtil.addListener('hotkeyPress', onHotkeyPress);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('hotkeyPress', onHotkeyPress);
});
</script>

<template>
    <DefaultLayout>
        <div class="custom-scrollbar flex-shrink flex-grow overflow-y-scroll p-2 dark:bg-gray-900">
            <template v-if="!isStreamErrored">
                <Card
                    v-for="(algorithm, index) in loadedAlgorithmsList"
                    :key="algorithm.algorithmName"
                    :class="{
                        active: index === selectedAlgoIndex,
                        'hover:cursor-not-allowed': uiStore.isInsideWebView,
                        'hover:cursor-pointer hover:border-gray-300': !uiStore.isInsideWebView,
                    }"
                    class="mx-[1px] mb-2 select-none overflow-hidden rounded"
                    @click="selectAlgorithm(algorithm.id)"
                >
                    <CardHeader class="p-4">
                        <CardTitle class="text-base">{{ algorithm.algorithmName }}</CardTitle>
                        <CardDescription class="mt-0">{{
                            algorithm.shortExplanation
                        }}</CardDescription>
                    </CardHeader>
                    <CardContent class="flex items-center px-4 pb-4 text-sm text-muted-foreground">
                        <div class="flex w-full items-center justify-between">
                            <div class="flex items-center">
                                <Clock class="me-1 h-4 w-4" />
                                {{ algorithm.timeComplexity }}
                                <span class="mx-2">|</span>
                                <Globe class="me-1 h-4 w-4" />
                                {{ algorithm.spaceComplexity }}
                            </div>
                            <HotkeyIndicator
                                v-if="index === selectedAlgoIndex"
                                :hotkey="Hotkey.Right"
                                class="bg-secondary opacity-80"
                            />
                            <HotkeyIndicator
                                v-else-if="
                                    typeof selectedAlgoIndex !== 'undefined' &&
                                    index === selectedAlgoIndex + 1
                                "
                                :hotkey="Hotkey.Down"
                                class="bg-secondary/10"
                            />
                            <HotkeyIndicator
                                v-else-if="
                                    typeof selectedAlgoIndex !== 'undefined' &&
                                    index === selectedAlgoIndex - 1
                                "
                                :hotkey="Hotkey.Up"
                                class="bg-secondary/10 opacity-80"
                            />
                        </div>
                    </CardContent>
                </Card>
            </template>

            <Card v-if="!isStreamErrored && isStreamLoading" class="mx-[1px] select-none rounded">
                <CardContent class="flex items-center justify-center p-6">
                    <Loader :width="24" :height="24" />
                </CardContent>
            </Card>

            <Card v-else-if="isStreamErrored" class="mx-[1px] select-none rounded">
                <CardContent class="flex items-center justify-center p-6">
                    <div class="flex flex-col items-center justify-center px-5 text-center">
                        <h4 class="mb-4 flex items-center text-destructive">
                            <CircleX class="me-2 h-5 w-5" />
                            Something went wrong while processing your input
                        </h4>
                        <p class="mb-4 max-w-lg text-sm text-muted-foreground">
                            Please make sure the selected input source is
                            <span class="underline">not minimized</span> and the Leetcode problem
                            description is fully visible to guarantee the best possible output.
                        </p>
                        <Button variant="outline" size="sm" @click="reset">
                            Reset
                            <HotkeyIndicator :hotkey="Hotkey.Reset" class="ms-2" />
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <Card
                v-else-if="!isStreamLoading && !isStreamErrored && loadedAlgorithmsList.length <= 0"
            >
                <CardContent class="flex select-none items-center justify-center p-6">
                    <div class="flex flex-col items-center justify-center px-5 text-center">
                        <h4 class="mb-4 flex items-center text-destructive">
                            <CircleX class="me-2 h-4 w-4" />
                            No valid problem could be found in your input:
                        </h4>
                        <img
                            class="mb-4 max-w-[50vw] rounded"
                            v-if="hostStore.activeImageData"
                            :src="hostStore.activeImageData"
                            alt="Submitted image"
                        />
                        <p class="mb-4 max-w-lg text-sm text-muted-foreground">
                            Please make sure the selected input source is
                            <span class="underline">not minimized</span> and the Leetcode problem
                            description is fully visible to guarantee the best possible output.
                        </p>
                        <Button variant="outline" size="sm" @click="reset">
                            Reset
                            <HotkeyIndicator :hotkey="Hotkey.Reset" class="ms-2" />
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    </DefaultLayout>
</template>

<style scoped>
.active {
    @apply border border-muted-foreground bg-card/5;
}
</style>
