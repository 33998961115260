import { useUiStore } from '../../../stores/UiStore.ts';
import { syncSetSettingsStoreAction } from '../../../actions/sync/SyncSetSettingsStoreAction.ts';
import { useSettingsStore } from '../../../stores/SettingsStore.ts';
import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { useWebSocketStore } from '../../../stores/WebSocketStore.ts';

export const connectedClientsMessageHandler = (messageData: WebSocketMessageData) => {
    if (messageData.clients !== undefined) {
        const webSocketStore = useWebSocketStore();
        webSocketStore.connectedClients = messageData.clients;
    }

    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        const settingsStore = useSettingsStore();
        syncSetSettingsStoreAction({
            programmingLanguage: settingsStore.programmingLanguage,
            hotkeys: settingsStore.hotkeys,
        });
    }
};
