<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import { useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { Input } from '../../components/ui/input';
import { registerAction } from '../../actions/auth/RegisterAction.ts';
import { focusRefElement } from '../../utils/AppUtil.ts';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { ROUTES } from '../../router.ts';
import { Button } from '../../components/ui/button';
import { useRouter } from 'vue-router';
import { handleFormError } from '../../utils/FormUtil.ts';
import { createTreasurerInputAction } from '../../actions/treasurer/CreateTreasurerInputAction.ts';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import { Info } from 'lucide-vue-next';
import Loader from '../../components/Loader.vue';

const router = useRouter();

const usernameInput = ref<typeof Input | null>(null);

const isLoading = ref(false);

const formSchema = toTypedSchema(
    z.object({
        _: z.unknown(),
        username: z.string().regex(/^\S*$/, 'Username should not contain spaces').min(2).max(50),
        password: z.string().min(8).max(100),
        referralSource: z.string().optional().or(z.literal('')),
    }),
);

const form = useForm({
    validationSchema: formSchema,
});

const onSubmit = form.handleSubmit(async (values): Promise<void> => {
    isLoading.value = true;

    if (values.username.includes('@')) {
        form.setErrors({
            username:
                'To protect your privacy, please do not use an email address as your username.',
        });

        isLoading.value = false;

        return;
    }

    try {
        await registerAction(values.username, values.password, values.referralSource);
    } catch (error) {
        isLoading.value = false;

        await handleFormError(form, error, false);

        return;
    }

    isLoading.value = false;

    await router.push(ROUTES.onboarding);
});

onMounted(() => {
    focusRefElement(usernameInput);

    void createTreasurerInputAction('4aa60f4682d722a100c4d6e77529bd4a');
});
</script>

<template>
    <DefaultLayout>
        <section class="h-full select-none">
            <div class="mx-auto flex h-full flex-col items-center justify-center">
                <h1 class="mb-6 flex items-center text-2xl font-semibold">🧙‍♂️ Leetcode Wizard</h1>
                <Card class="w-full max-w-sm md:mt-0 xl:p-0">
                    <CardHeader>
                        <CardTitle class="text-xl">Register</CardTitle>
                    </CardHeader>

                    <CardContent>
                        <form @submit="onSubmit">
                            <div v-if="form.errorBag.value._">
                                <ul class="mb-4">
                                    <li
                                        v-for="(error, index) in form.errorBag.value._"
                                        :key="index"
                                        class="text-sm font-medium text-destructive"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>

                            <FormField
                                v-slot="{ componentField, errors }"
                                name="username"
                                :validate-on-input="false"
                                :validate-on-model-update="false"
                                :validate-on-blur="false"
                            >
                                <FormItem class="mb-4">
                                    <FormLabel>Username</FormLabel>
                                    <FormControl>
                                        <Input
                                            ref="usernameInput"
                                            type="text"
                                            v-bind="componentField"
                                            :disabled="isLoading"
                                        />
                                    </FormControl>
                                    <FormMessage v-if="errors">
                                        {{ errors }}
                                    </FormMessage>
                                </FormItem>
                            </FormField>

                            <FormField
                                v-slot="{ componentField, errors }"
                                name="password"
                                :validate-on-input="false"
                                :validate-on-model-update="false"
                                :validate-on-blur="false"
                            >
                                <FormItem class="mb-4">
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            v-bind="componentField"
                                            :disabled="isLoading"
                                        />
                                    </FormControl>
                                    <FormMessage v-if="errors">
                                        {{ errors }}
                                    </FormMessage>
                                </FormItem>
                            </FormField>

                            <hr class="my-4" />

                            <FormField
                                v-slot="{ componentField, errors }"
                                name="referralSource"
                                :validate-on-input="false"
                                :validate-on-model-update="false"
                                :validate-on-blur="false"
                            >
                                <FormItem class="mb-4">
                                    <FormLabel
                                        >Where did you first hear about us? (optional)</FormLabel
                                    >
                                    <FormControl>
                                        <Input
                                            type="text"
                                            v-bind="componentField"
                                            :disabled="isLoading"
                                        />
                                    </FormControl>
                                    <FormMessage v-if="errors">
                                        {{ errors }}
                                    </FormMessage>
                                </FormItem>
                            </FormField>

                            <Button
                                type="submit"
                                :disabled="isLoading"
                                class="flex w-20 items-center justify-center"
                            >
                                <span v-if="!isLoading">Submit</span>
                                <Loader v-else :width="18" :height="18" />
                            </Button>
                        </form>
                    </CardContent>
                    <CardFooter class="flex flex-row">
                        <p class="text-sm text-muted-foreground">
                            Already have an account?
                            <RouterLink :to="ROUTES.authLogin" class="font-medium hover:underline">
                                Log in here
                            </RouterLink>
                        </p>
                    </CardFooter>
                </Card>

                <div
                    class="mt-4 flex w-full max-w-sm items-center rounded-lg border border-blue-300 bg-card p-4 text-sm text-blue-400 dark:border-blue-800"
                    role="alert"
                >
                    <Info class="me-3 inline h-4 w-4 flex-shrink-0" />
                    <span class="sr-only">Info</span>
                    <div>
                        The privacy of our users is our highest priority. We do not collect email
                        addresses. If you forget your password, please create a new account or
                        contact our support team.
                    </div>
                </div>
            </div>
        </section>
    </DefaultLayout>
</template>
