<script setup lang="ts">
import { toggleDarkModeAction } from '../../actions/app/ToggleDarkModeAction.ts';
import { SunMedium, Moon } from 'lucide-vue-next';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

const settingsStore = useSettingsStore();

const toggleDarkMode = () => {
    toggleDarkModeAction();
};
</script>

<template>
    <TooltipProvider :delay-duration="200">
        <Tooltip>
            <TooltipTrigger as-child>
                <div @click="toggleDarkMode" class="hover:cursor-pointer">
                    <SunMedium v-if="settingsStore.darkModeEnabled" class="toggle-icon" />
                    <Moon v-else class="toggle-icon" />
                </div>
            </TooltipTrigger>
            <TooltipContent>
                <p>Toggle theme</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
</template>

<style scoped>
.toggle-icon {
    @apply h-4 w-4 text-muted-foreground hover:text-muted-foreground/80;
}
</style>
