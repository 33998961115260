<script setup lang="ts">
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { onMounted, ref } from 'vue';
import { ProgrammingLanguage } from '../../types/ProgrammingLanguage.ts';
import {
    MenubarCheckboxItem,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
} from '../ui/menubar';

const settingsStore = useSettingsStore();

const currentValue = ref<ProgrammingLanguage>(ProgrammingLanguage['Python']);

const setLanguage = (language: ProgrammingLanguage) => {
    settingsStore.programmingLanguage = language;
};

onMounted(() => {
    currentValue.value = settingsStore.programmingLanguage;
});
</script>

<template>
    <MenubarSub>
        <MenubarSubTrigger>Programming language</MenubarSubTrigger>
        <MenubarSubContent>
            <MenubarCheckboxItem
                v-for="language in Object.values(ProgrammingLanguage).filter((v) =>
                    isNaN(Number(v)),
                )"
                :key="language"
                :checked="language === settingsStore.programmingLanguage"
                class="py-0.5 hover:cursor-pointer"
                @click="setLanguage(language)"
                @select="(event: Event) => event.preventDefault()"
            >
                {{ language }}
            </MenubarCheckboxItem>
        </MenubarSubContent>
    </MenubarSub>
</template>
