import { acceptHMRUpdate, defineStore } from 'pinia';
import { InputSource } from '../types/electron/InputSource.ts';

interface State {
    desktopAppVersion: string;
    sources: InputSource[];
    sourcesLoading: boolean;
    activeImageData: string | null;

    // Source recovery
    lostSourceId: string | null;
    lostSourceName: string | null;
    selectedSourceId: string | null;
    selectedSourceName: string | null;
}

export const useHostStore = defineStore('host', {
    state: (): State => {
        return {
            desktopAppVersion: '',
            sources: [],
            sourcesLoading: false,
            activeImageData: null,

            // Source recovery
            lostSourceId: null,
            lostSourceName: null,
            selectedSourceId: null,
            selectedSourceName: null,
        };
    },
    persist: {
        storage: sessionStorage,
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHostStore, import.meta.hot));
}
