<script setup lang="ts">
import { useHostStore } from '../../stores/HostStore.ts';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../ui/hover-card';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { Badge } from '../ui/badge';

/* eslint-disable no-undef */
const clientVersion = __APP_VERSION__;

const hostStore = useHostStore();
const settingsStore = useSettingsStore();

const updateLastSeenChangelogVersion = () => {
    setTimeout(() => {
        settingsStore.lastSeenChangelogVersion = clientVersion;
    }, 150);
};
</script>

<template>
    <HoverCard :open-delay="100">
        <HoverCardTrigger
            class="flex items-center text-xs text-muted-foreground hover:cursor-pointer"
            @mouseenter="updateLastSeenChangelogVersion"
        >
            <span v-if="hostStore.desktopAppVersion && clientVersion">
                {{ `${hostStore.desktopAppVersion}` }} |
                {{ `${clientVersion}` }}
            </span>
            <span v-else-if="clientVersion">
                {{ `${clientVersion}` }}
            </span>
            <div
                class="changelog-dot"
                v-if="settingsStore.lastSeenChangelogVersion !== clientVersion"
            ></div>
        </HoverCardTrigger>
        <HoverCardContent class="w-[50vh] text-xs">
            <h3 class="mb-3 text-base font-medium">Changelog</h3>
            <div class="custom-scrollbar max-h-[200px] overflow-y-auto overflow-x-hidden">
                <div class="changelog-entry">
                    App 2.3.0 (10 Oct 2024)
                    <Badge class="ms-1 bg-green-600 px-1 py-0 font-normal text-gray-100">New</Badge>
                    <ul class="ml-4 mt-1 list-disc">
                        <li>Updated to latest Electron version (v32)</li>
                        <li>Added a warning notification when hotkeys fail to register</li>
                    </ul>
                </div>
                <div class="changelog-entry">
                    Client 2.2.1 (9 Oct 2024)
                    <Badge class="ms-1 bg-green-600 px-1 py-0 font-normal text-gray-100">New</Badge>
                    <ul class="ml-4 mt-1 list-disc">
                        <li>
                            Fixed a bug where the output panels would sometimes show markdown
                            fragments
                        </li>
                        <li>Fixed a bug where the web view connection bar would be empty</li>
                        <li>
                            Fixed a bug where the login submit button would disappear under certain
                            conditions
                        </li>
                    </ul>
                </div>
                <div class="changelog-entry">
                    Client 2.2.0 (30 Sep 2024)
                    <Badge class="ms-1 bg-green-600 px-1 py-0 font-normal text-gray-100">New</Badge>
                    <ul class="ml-4 mt-1 list-disc">
                        <li>
                            Added the ability to switch AI models (Options -> Configure AI model)
                        </li>
                        <li>Added support for Claude 3.5 Sonnet</li>
                        <li>Finally started a changelog 😀</li>
                    </ul>
                </div>
            </div>
            <div class="mt-4 text-muted-foreground">
                Client version: {{ clientVersion }}<br />
                App (Electron) version: {{ hostStore.desktopAppVersion }}<br />
            </div>
        </HoverCardContent>
    </HoverCard>
</template>

<style>
.changelog-dot {
    @apply ms-1.5 h-1.5 w-1.5 bg-green-500;
    border-radius: 50%;
    display: inline-block;
}

.changelog-entry:not(:last-child) {
    @apply mb-3;
}
</style>
