<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import { useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { Input } from '../../components/ui/input';
import { logInAction } from '../../actions/auth/LogInAction.ts';
import { focusRefElement } from '../../utils/AppUtil.ts';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import Loader from '../../components/Loader.vue';
import { ROUTES } from '../../router.ts';
import { Button } from '../../components/ui/button';
import { handleFormError } from '../../utils/FormUtil.ts';
import { useRouter } from 'vue-router';
import { useUiStore } from '../../stores/UiStore.ts';
import DefaultLayout from '../../layouts/DefaultLayout.vue';

const VITE_LANDING_PAGE_BASE_URL = import.meta.env.VITE_LANDING_PAGE_BASE_URL;

const router = useRouter();
const settingsStore = useSettingsStore();
const uiStore = useUiStore();

const usernameInput = ref<typeof Input | null>(null);
const passwordInput = ref<typeof Input | null>(null);

const isLoading = ref(false);

const formSchema = toTypedSchema(
    z.object({
        _: z.unknown(),
        username: z.string(),
        password: z.string(),
    }),
);

const form = useForm({
    validationSchema: formSchema,
});

const onSubmit = form.handleSubmit(async (values): Promise<void> => {
    isLoading.value = true;
    try {
        await logInAction(values.username, values.password);
    } catch (error) {
        isLoading.value = false;

        await handleFormError(form, error, false);

        return;
    }

    isLoading.value = false;

    await router.push({ name: ROUTES.index });
});

onMounted(() => {
    if (import.meta.env.DEV) {
        form.setValues({
            username: 'admin',
            password: 'foobar123',
        });
    } else {
        if (settingsStore.rememberedUsername) {
            form.setValues(
                {
                    username: settingsStore.rememberedUsername,
                },
                false,
            );
            focusRefElement(passwordInput);
        } else {
            focusRefElement(usernameInput);
        }
    }
});
</script>

<template>
    <DefaultLayout>
        <section class="h-full select-none">
            <div class="mx-auto flex h-full flex-col items-center justify-center">
                <h1 class="mb-6 flex items-center text-2xl font-semibold">🧙‍♂️ Leetcode Wizard</h1>
                <Card class="w-full max-w-sm md:mt-0 xl:p-0">
                    <CardHeader>
                        <CardTitle class="text-xl">Login</CardTitle>
                    </CardHeader>

                    <CardContent>
                        <form @submit="onSubmit">
                            <div v-if="form.errorBag.value._">
                                <ul class="mb-4">
                                    <li
                                        v-for="(error, index) in form.errorBag.value._"
                                        :key="index"
                                        class="text-sm font-medium text-destructive"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>

                            <FormField
                                v-slot="{ componentField, errors }"
                                name="username"
                                :validate-on-input="false"
                                :validate-on-model-update="false"
                                :validate-on-blur="false"
                            >
                                <FormItem class="mb-4">
                                    <FormLabel>Username</FormLabel>
                                    <FormControl>
                                        <Input
                                            ref="usernameInput"
                                            type="text"
                                            v-bind="componentField"
                                            :disabled="isLoading"
                                        />
                                    </FormControl>
                                    <FormMessage v-if="errors">
                                        {{ errors }}
                                    </FormMessage>
                                </FormItem>
                            </FormField>

                            <FormField
                                v-slot="{ componentField, errors }"
                                name="password"
                                :validate-on-input="false"
                                :validate-on-model-update="false"
                                :validate-on-blur="false"
                            >
                                <FormItem class="mb-4">
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            ref="passwordInput"
                                            type="password"
                                            v-bind="componentField"
                                            :disabled="isLoading"
                                        />
                                    </FormControl>
                                    <FormMessage v-if="errors">
                                        {{ errors }}
                                    </FormMessage>
                                </FormItem>
                            </FormField>

                            <Button
                                type="submit"
                                :disabled="isLoading"
                                class="flex w-20 items-center justify-center"
                            >
                                <span v-if="!isLoading">Submit</span>
                                <Loader v-else :width="18" :height="18" />
                            </Button>
                        </form>
                    </CardContent>
                    <CardFooter>
                        <p v-if="uiStore.isInsideWebView" class="text-sm text-muted-foreground">
                            Don't have an account yet?
                            <a
                                :href="`${VITE_LANDING_PAGE_BASE_URL}/download?referer=web_view`"
                                class="text-primary-500 font-medium hover:cursor-pointer"
                                target="_blank"
                            >
                                Download our desktop app to register
                            </a>
                        </p>
                        <p v-else class="text-sm text-muted-foreground">
                            Don't have an account yet?
                            <RouterLink
                                :to="ROUTES.authRegister"
                                class="font-medium hover:underline"
                            >
                                Register here
                            </RouterLink>
                        </p>
                    </CardFooter>
                </Card>
            </div>
        </section>
    </DefaultLayout>
</template>
