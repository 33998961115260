import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ProgrammingLanguage } from '../types/ProgrammingLanguage.ts';
import { Hotkey } from '../types/Hotkey.ts';
import { syncSetSettingsStoreAction } from '../actions/sync/SyncSetSettingsStoreAction.ts';
import { watch } from 'vue';
import { AI_MODEL } from '../constants/AiModel.ts';

interface State {
    programmingLanguage: ProgrammingLanguage;
    opacity: number;
    appAlwaysOnTop: boolean;
    darkModeEnabled: boolean;
    rememberedUsername: string;
    hotkeys: { [key: string]: string };
    isOnboarding: boolean;
    hideWarningShown: boolean;
    activeAiModel: AI_MODEL;
    lastSeenChangelogVersion: string | undefined;
}

const useSettingsStore = defineStore('settings', {
    state: (): State => {
        return {
            programmingLanguage: ProgrammingLanguage.Python3,
            opacity: 100,
            appAlwaysOnTop: false,
            darkModeEnabled: true,
            rememberedUsername: '',
            hotkeys: {
                [Hotkey.Up]: 'CmdOrCtrl+Up',
                [Hotkey.Down]: 'CmdOrCtrl+Down',
                [Hotkey.Left]: 'CmdOrCtrl+Left',
                [Hotkey.Right]: 'CmdOrCtrl+Right',
                [Hotkey.Solve]: 'CmdOrCtrl+1',
                [Hotkey.Reset]: 'CmdOrCtrl+2',
                [Hotkey.Hide]: 'CmdOrCtrl+3',
                [Hotkey.Quit]: 'CmdOrCtrl+4',
            },
            isOnboarding: false,
            hideWarningShown: false,
            activeAiModel: AI_MODEL['gpt-4o'],
            lastSeenChangelogVersion: undefined,
        };
    },
    actions: {
        resetShortcuts() {
            this.hotkeys = {
                [Hotkey.Up]: 'CmdOrCtrl+Up',
                [Hotkey.Down]: 'CmdOrCtrl+Down',
                [Hotkey.Left]: 'CmdOrCtrl+Left',
                [Hotkey.Right]: 'CmdOrCtrl+Right',
                [Hotkey.Solve]: 'CmdOrCtrl+1',
                [Hotkey.Reset]: 'CmdOrCtrl+2',
                [Hotkey.Hide]: 'CmdOrCtrl+3',
                [Hotkey.Quit]: 'CmdOrCtrl+4',
            };
        },
    },
    persist: {
        storage: localStorage,
    },
});

export const registerSettingsStoreWatcher = () => {
    const settingsStore = useSettingsStore();
    const { programmingLanguage, hotkeys } = storeToRefs(settingsStore);

    watch([programmingLanguage, hotkeys], async ([newProgrammingLanguage, newHotkeys]) => {
        syncSetSettingsStoreAction({
            programmingLanguage: newProgrammingLanguage,
            hotkeys: newHotkeys,
        });
    });
};

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}

export { useSettingsStore };
