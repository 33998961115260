<script setup lang="ts">
import { computed, ComputedRef, onMounted, onUnmounted, ref } from 'vue';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { ClipboardCopy } from 'lucide-vue-next';
import copy from 'copy-to-clipboard';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { useClientStore } from '../../stores/ClientStore.ts';
import { useRoute } from 'vue-router';
import { Button } from '../../components/ui/button';
import CodeOutputFrame from '../../components/outputFrame/CodeOutputFrame.vue';
import { PROGRAMMING_LANGUAGE_HIGHLIGHT_JS } from '../../constants/ProgrammingLanguageHiglightJs.ts';
import { generateCodeStreamId } from '../../ws/id/GenerateCodeStreamId.ts';
import { generateTestsStreamId } from '../../ws/id/GenerateTestsStreamId.ts';
import { generateComplexityStreamId } from '../../ws/id/GenerateComplexityStreamId.ts';
import Loader from '../../components/Loader.vue';
import MarkdownOutputFrame from '../../components/outputFrame/MarkdownOutputFrame.vue';
import { Hotkey } from '../../types/Hotkey.ts';
import { router, ROUTES } from '../../router.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import DefaultLayout from '../../layouts/DefaultLayout.vue';

const route = useRoute();

const clientStore = useClientStore();

const outputBody = ref<HTMLDivElement | null>(null);
const lastScrollTime = ref<number | null>(null);

const copyCodeToClipboard = () => {
    copy(outputCode.value);
    createTextToast('Code copied to clipboard', undefined, 'default', false);
};

const scrollInDirection = (up: boolean) => {
    if (!outputBody.value) {
        return;
    }

    outputBody.value.scrollBy({
        top: up ? -200 : 200,
        behavior: 'smooth',
    });

    const now = window.performance.now();
    if (lastScrollTime.value) {
        const diff = now - lastScrollTime.value;
        if (diff < 70) {
            outputBody.value.scrollBy({
                top: up ? -50 : 50,
                behavior: 'instant',
            });
        }
    }

    lastScrollTime.value = now;
};

const onHotkeyPress = (hotkey: Hotkey) => {
    if (hotkey === Hotkey.Left) {
        router.push({
            name: ROUTES.solveAlgorithmSelection,
            params: {
                solveId: String(route.params.solveId),
            },
        });
    } else if (hotkey === Hotkey.Down) {
        scrollInDirection(false);
    } else if (hotkey === Hotkey.Up) {
        scrollInDirection(true);
    }
};

const selectedOutputIndex: ComputedRef<number> = computed(() => {
    return 0;
});

const highlightJsLanguage: ComputedRef<string> = computed(() => {
    const programmingLanguage = clientStore.solveProgrammingLanguage[String(route.params.solveId)];

    return PROGRAMMING_LANGUAGE_HIGHLIGHT_JS[programmingLanguage];
});

/* region Streams */

const getCodeStreamId = () => {
    return generateCodeStreamId(String(route.params.solveId), String(route.params.algorithmId));
};

const outputCode = computed(() => {
    return clientStore.streams[getCodeStreamId()] ?? '';
});

const isCodeStreamWaiting = computed(() => {
    return (
        clientStore.streamsWaiting.includes(getCodeStreamId()) ||
        !clientStore.streams[getCodeStreamId()]
    );
});

const isCodeStreamErrored = computed(() => {
    return clientStore.streamsErrored.includes(getCodeStreamId());
});

const getTestsStreamId = () => {
    return generateTestsStreamId(String(route.params.solveId), String(route.params.algorithmId));
};

const outputTests = computed(() => {
    return clientStore.streams[getTestsStreamId()] ?? '';
});

const isTestsStreamWaiting = computed(() => {
    return (
        clientStore.streamsWaiting.includes(getTestsStreamId()) ||
        !clientStore.streams[getTestsStreamId()]
    );
});

const isTestsStreamErrored = computed(() => {
    return clientStore.streamsErrored.includes(getTestsStreamId());
});

const isTestsStreamCancelled = computed(() => {
    return clientStore.streamCancelled.includes(getTestsStreamId());
});

const getComplexityStreamId = () => {
    return generateComplexityStreamId(
        String(route.params.solveId),
        String(route.params.algorithmId),
    );
};

const outputComplexity = computed(() => {
    return clientStore.streams[getComplexityStreamId()] ?? '';
});

const isComplexityStreamWaiting = computed(() => {
    return (
        clientStore.streamsWaiting.includes(getComplexityStreamId()) ||
        !clientStore.streams[getComplexityStreamId()]
    );
});

const isComplexityStreamErrored = computed(() => {
    return clientStore.streamsErrored.includes(getComplexityStreamId());
});

const isComplexityStreamCancelled = computed(() => {
    return clientStore.streamCancelled.includes(getComplexityStreamId());
});

/* endregion Streams */

onMounted(() => {
    eventEmitterUtil.addListener('hotkeyPress', onHotkeyPress);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('hotkeyPress', onHotkeyPress);
});
</script>

<template>
    <DefaultLayout>
        <div
            ref="outputBody"
            class="custom-scrollbar flex-shrink flex-grow overflow-y-scroll p-2 dark:bg-gray-900"
        >
            <Card class="output-card mb-2">
                <CardHeader class="output-card__header">
                    <CardTitle>
                        <div class="flex items-center justify-between text-base">
                            Code
                            <Button
                                v-if="selectedOutputIndex === 0"
                                :disabled="outputCode.length <= 0"
                                variant="secondary"
                                size="sm"
                                class="h-0.25 px-2.5 py-1"
                                @click="copyCodeToClipboard"
                            >
                                <span class="hidden min-[320px]:inline">Copy to clipboard</span>
                                <ClipboardCopy class="h-4 w-4 min-[320px]:ms-2" />
                            </Button>
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardContent class="output-card__content">
                    <div
                        v-if="isCodeStreamErrored"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        Failed to retrieve output
                    </div>
                    <div
                        v-else-if="isCodeStreamWaiting"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        <Loader :width="24" :height="24" />
                    </div>
                    <CodeOutputFrame
                        v-else
                        :content="outputCode"
                        :highlight-js-language="highlightJsLanguage"
                    />
                </CardContent>
            </Card>

            <Card class="output-card mb-2">
                <CardHeader class="output-card__header">
                    <CardTitle>
                        <div class="flex items-center justify-between text-base">Tests</div>
                    </CardTitle>
                </CardHeader>
                <CardContent class="output-card__content">
                    <div
                        v-if="isTestsStreamErrored"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        Failed to retrieve output
                    </div>
                    <div
                        v-else-if="isTestsStreamCancelled"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        Request cancelled
                    </div>
                    <div
                        v-else-if="isTestsStreamWaiting"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        <Loader :width="24" :height="24" />
                    </div>
                    <CodeOutputFrame
                        v-else
                        :content="outputTests"
                        :highlight-js-language="highlightJsLanguage"
                    />
                </CardContent>
            </Card>

            <Card class="output-card">
                <CardHeader class="output-card__header">
                    <CardTitle>
                        <div class="flex items-center justify-between text-base">Complexity</div>
                    </CardTitle>
                </CardHeader>
                <CardContent class="output-card__content">
                    <div
                        v-if="isComplexityStreamErrored"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        Failed to retrieve output
                    </div>
                    <div
                        v-else-if="isComplexityStreamCancelled"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        Request cancelled
                    </div>
                    <div
                        v-else-if="isComplexityStreamWaiting"
                        class="flex items-center justify-center p-5 text-muted-foreground"
                    >
                        <Loader :width="24" :height="24" />
                    </div>
                    <MarkdownOutputFrame v-else :content="outputComplexity" />
                </CardContent>
            </Card>
        </div>
    </DefaultLayout>
</template>

<style scoped>
.output-card {
    @apply rounded border bg-gray-200 dark:bg-gray-900;
}

.output-card__content {
    @apply rounded bg-card p-0;
}

.output-card__header {
    @apply select-none px-4 py-2 pe-2 text-lg;
}
</style>
