import { useWebSocketStore } from '../stores/WebSocketStore.ts';
import { WS_REQUEST, WS_RESPONSE } from './WebSocketEvents.ts';
import { AuthenticationService } from '../services/AuthenticationService.ts';
import { wsAuthLogIn } from './requests/WsAuthLogIn.ts';
import { useClientStore } from '../stores/ClientStore.ts';
import { solveRequestAlgorithmsStreamContentHandler } from './handlers/stream/SolveRequestAlgorithmsStreamContentHandler.ts';
import { WebSocketStreamDataType } from '../types/ws/WebSocketStreamDataType.ts';
import { solveRequestCodeStreamEndHandler } from './handlers/stream/SolveRequestCodeStreamEndHandler.ts';
import { WebSocketStreamData } from '../types/ws/WebSocketStreamData.ts';
import { syncHotkeyMessageHandler } from './handlers/message/SyncHotkeyMessageHandler.ts';
import { syncPushToastMessageHandler } from './handlers/message/SyncPushToastMessageHandler.ts';
import { syncSetRouteMessageHandler } from './handlers/message/SyncSetRouteMessageHandler.ts';
import { syncSetClientStoreMessageHandler } from './handlers/message/SyncSetClientStoreMessageHandler.ts';
import { solveRequestAlgorithmsStreamStartHandler } from './handlers/stream/SolveRequestAlgorithmsStreamStartHandler.ts';
import { syncShowSolvePreviewMessageHandler } from './handlers/message/SyncShowSolvePreviewMessageHandler.ts';
import { syncSetSettingsStoreMessageHandler } from './handlers/message/SyncSetSettingsStoreMessageHandler.ts';
import { syncSetSolveAlgorithmsMessageHandler } from './handlers/message/SyncSetSolveAlgorithmsMessageHandler.ts';
import { WebSocketMessageData } from '../types/ws/WebSocketMessageData.ts';
import { connectedClientsMessageHandler } from './handlers/message/ConnectedClientsMessageHandler.ts';
import { useUiStore } from '../stores/UiStore.ts';
import { solveRequestRetrieveUserInfoHandler } from './handlers/stream/SolveRequestRetrieveUserInfoHandler.ts';

const MAX_SEND_RETRY_ATTEMPTS = 5;

class WebSocketService {
    static #instance: WebSocketService;

    private socket: WebSocket | undefined = undefined;

    streamMessageHandlers: {
        [key: string]: { [key: string]: (messageData: WebSocketStreamData) => void };
    } = {
        [WS_RESPONSE.solve_requestAlgorithms]: {
            [WebSocketStreamDataType.Start]: solveRequestAlgorithmsStreamStartHandler,
            [WebSocketStreamDataType.Content]: solveRequestAlgorithmsStreamContentHandler,
            [WebSocketStreamDataType.End]: solveRequestRetrieveUserInfoHandler,
        },
        [WS_RESPONSE.solve_requestCode]: {
            [WebSocketStreamDataType.End]: solveRequestCodeStreamEndHandler,
        },
        [WS_RESPONSE.solve_requestTests]: {
            [WebSocketStreamDataType.End]: solveRequestRetrieveUserInfoHandler,
        },
        [WS_RESPONSE.solve_requestComplexity]: {
            [WebSocketStreamDataType.End]: solveRequestRetrieveUserInfoHandler,
        },
    };

    messageHandlers: { [key: string]: (messageData: WebSocketMessageData) => void } = {
        [WS_RESPONSE.connectedClients]: connectedClientsMessageHandler,
        [WS_RESPONSE.sync_hotkey]: syncHotkeyMessageHandler,
        [WS_RESPONSE.sync_pushToast]: syncPushToastMessageHandler,
        [WS_RESPONSE.sync_setRoute]: syncSetRouteMessageHandler,
        [WS_RESPONSE.sync_setClientStore]: syncSetClientStoreMessageHandler,
        [WS_RESPONSE.sync_setSettingsStore]: syncSetSettingsStoreMessageHandler,
        [WS_RESPONSE.sync_setSetSolveAlgorithms]: syncSetSolveAlgorithmsMessageHandler,
        [WS_RESPONSE.sync_showSolvePreview]: syncShowSolvePreviewMessageHandler,
    };

    public static get instance(): WebSocketService {
        if (!WebSocketService.#instance) {
            WebSocketService.#instance = new WebSocketService();
        }

        return WebSocketService.#instance;
    }

    public connect(): void {
        if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
            return;
        }

        const webSocketStore = useWebSocketStore();
        webSocketStore.shouldReconnect = true;
        webSocketStore.showWebSocketStatus = true;

        this.socket = new WebSocket(import.meta.env.VITE_WS_SERVER_URL);

        this.socket.addEventListener('open', () => {
            webSocketStore.isConnected = true;

            const isLoggedIn = AuthenticationService.instance.isLoggedIn();
            const accessToken = AuthenticationService.instance.getAccessToken();

            if (isLoggedIn && accessToken) {
                const uiStore = useUiStore();
                wsAuthLogIn(accessToken, !uiStore.isInsideWebView);
            }
        });

        this.socket.addEventListener('message', (message) => {
            let messageData;
            try {
                messageData = JSON.parse(message.data);
            } catch (e) {
                console.error('WebSocket parsing error:', e);

                return;
            }

            if (messageData.event === undefined) {
                return;
            }

            const event = messageData.event;
            if (event === WS_RESPONSE.rateLimited) {
                throw new Error('Rate limited by WS server');
            }

            const data = messageData.data;

            if (data?.streamId !== undefined) {
                this.handleStreamMessage(data);

                if (
                    event !== undefined &&
                    messageData.data?.type !== undefined &&
                    this.streamMessageHandlers[event] !== undefined &&
                    this.streamMessageHandlers[event][messageData.data.type] !== undefined
                ) {
                    this.streamMessageHandlers[event][messageData.data.type](data);
                }
            } else {
                this.handleMessage();

                if (event !== undefined && this.messageHandlers[event] !== undefined) {
                    this.messageHandlers[event](data);
                }
            }
        });

        this.socket.addEventListener('close', () => {
            webSocketStore.isConnected = false;
        });
    }

    public verifyConnection(): void {
        if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
            this.connect();
        }
    }

    public disconnect(): void {
        if (this.socket) {
            this.socket.close();
        }

        const webSocketStore = useWebSocketStore();
        webSocketStore.shouldReconnect = false;

        setTimeout(() => {
            webSocketStore.showWebSocketStatus = false;
        }, 2_500);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public send(requestEvent: WS_REQUEST, data: any, retryAttempt = 0): void {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            if (retryAttempt > MAX_SEND_RETRY_ATTEMPTS) {
                console.warn(
                    `Failed to send "${requestEvent}" message after ${MAX_SEND_RETRY_ATTEMPTS} retries, terminating send.`,
                );

                return;
            }

            console.warn(
                `Failed to send "${requestEvent}" message, socket is still undefined or not opened yet, retrying in 100ms (attempt ${retryAttempt + 1}).`,
            );

            setTimeout(() => {
                this.send(requestEvent, data, retryAttempt + 1);
            }, 100);

            return;
        }

        try {
            this.socket.send(
                JSON.stringify({
                    event: requestEvent,
                    data,
                }),
            );
        } catch (error) {
            console.error('Failed to send WebSocket message:', error);
        }
    }

    private handleStreamMessage(messageData: WebSocketStreamData): void {
        const clientStore = useClientStore();

        const type = messageData.type;
        const streamId = messageData.streamId;

        // Add to output if it is a data type, we don't add start and finish to the output.
        if (type === WebSocketStreamDataType.Start) {
            // Prepare output
            clientStore.streams[streamId] = '';

            // Remove from "waiting" state
            clientStore.streamsWaiting = clientStore.streamsWaiting.filter(
                (id: string) => id !== streamId,
            );

            // Remove from "errored state
            clientStore.streamsErrored = clientStore.streamsErrored.filter(
                (id: string) => id !== streamId,
            );

            // Add to "in progress" state
            clientStore.streamsInProgress = [...clientStore.streamsInProgress, streamId];
        }

        if (type === WebSocketStreamDataType.Content) {
            const content = messageData.content;
            clientStore.streams[streamId] += content;
        }

        if (type === WebSocketStreamDataType.End) {
            // Remove from "in progress" state
            clientStore.streamsInProgress = clientStore.streamsInProgress.filter(
                (id: string) => id !== streamId,
            );
        }
    }

    private handleMessage(): void {
        // @TODO
    }
}

export { WebSocketService };
