<script setup lang="ts">
import { makeAlgorithmsRequestAction } from '../../actions/solve/SolveAction.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { CircleCheck, TriangleAlert } from 'lucide-vue-next';
import { Button } from '../ui/button';

interface ComponentState {
    inputSourceName: string | null;
    imageData: string | undefined;
    solveId: string | undefined;
    isOpen: boolean;
    shownImage: number;
}

const state: ComponentState = reactive({
    inputSourceName: null,
    imageData: undefined,
    solveId: undefined,
    isOpen: false,
    shownImage: 0,
});

const openModal = (inputSourceName: string | null, imageData: string, solveId: string) => {
    state.inputSourceName = inputSourceName;
    state.imageData = imageData;
    state.imageData = imageData;
    state.solveId = solveId;
    state.shownImage = 0;
    state.isOpen = true;
};

const showImage = (shownImage: number) => {
    state.shownImage = shownImage;
};

const ignoreWarning = () => {
    state.isOpen = false;

    if (state.imageData && state.solveId) {
        makeAlgorithmsRequestAction(state.imageData, state.solveId);
    }
};

onMounted(() => {
    eventEmitterUtil.addListener('showTrialInputWarning', openModal);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('showTrialInputWarning', openModal);
});
</script>

<template>
    <Dialog v-model:open="state.isOpen">
        <DialogContent
            class="custom-scrollbar max-h-[calc(100vh-100px)] min-w-[calc(70vw-100px)] overflow-y-scroll"
        >
            <DialogHeader class="mb-4">
                <DialogTitle> Trial input warning </DialogTitle>
            </DialogHeader>

            <div
                v-if="state.shownImage !== 0"
                class="image-overlay absolute z-50 flex h-full w-full items-center justify-center p-4 backdrop-blur hover:cursor-zoom-out"
                @click="showImage(0)"
            >
                <img
                    v-if="state.shownImage === 1"
                    class="rounded"
                    src="./../../assets/img/example-input-1.png"
                    alt="Example usage 1"
                />
                <img
                    v-else-if="state.shownImage === 2"
                    class="rounded"
                    src="./../../assets/img/example-input-2.png"
                    alt="Example usage 2"
                />
                <img
                    v-else-if="state.shownImage === 3 && state.imageData"
                    class="rounded"
                    :src="state.imageData"
                    alt="Example usage 1"
                />
            </div>

            <div class="gap-0 text-sm">
                <p>
                    We were unable to detect a LeetCode.com problem in your input<span
                        v-if="state.inputSourceName"
                    >
                        ("{{ state.inputSourceName }}")</span
                    >.
                </p>

                <div class="relative my-3 rounded border border-gray-600 bg-gray-900 px-4 py-2">
                    <p class="mb-1 flex items-center text-green-600">
                        <CircleCheck class="me-2 h-4 w-4" />
                        Examples of valid input:
                    </p>
                    <div class="mb-4 flex items-center gap-x-2">
                        <div class="w-1/2 hover:cursor-zoom-in" @click="showImage(1)">
                            <img
                                class="me-2 rounded"
                                src="./../../assets/img/example-input-1.png"
                                alt="Example usage 1"
                            />
                        </div>
                        <div class="w-1/2 hover:cursor-zoom-in" @click="showImage(2)">
                            <img
                                class="rounded"
                                src="./../../assets/img/example-input-2.png"
                                alt="Example usage 2"
                            />
                        </div>
                    </div>

                    <div v-if="state.imageData">
                        <p class="mb-1 flex items-center text-red-600">
                            <TriangleAlert class="me-2 h-4 w-4" />
                            Your input:
                        </p>
                        <img
                            class="mb-4 me-2 w-1/2 rounded hover:cursor-zoom-in"
                            :src="state.imageData"
                            alt="User input"
                            @click="showImage(3)"
                        />
                    </div>
                </div>

                <p class="mb-2">
                    The trial mode of Leetcode Wizard only works on
                    <a href="https://leetcode.com/problemset/" target="_blank" class="underline"
                        >LeetCode.com</a
                    >. Our Pro version works on all websites.
                </p>

                <hr class="my-2" />

                <p>
                    If this is a mistake please press the button below to continue. This warning is
                    only shown during trial mode.
                </p>
            </div>

            <DialogFooter>
                <Button type="button" @click="ignoreWarning">
                    <span>Ignore warning</span>
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
