// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
    AnalyticsDashboardIndexResponseDto,
    BadRequestResponseDto,
    CreateAffiliateRequestDto,
    CreateAffiliateResponseDto,
    CreateAnalyticsEventRequestDto,
    CreateCheckoutSessionResponseDto,
    IndexResponseDto,
    InternalServerErrorResponseDto,
    LandingPageStatsResponseDto,
    LinkAffiliateRequestDto,
    LoginRequestDto,
    LoginResponseDto,
    LoginTransferTokenRequestDto,
    LoginTransferTokenResponseDto,
    RefreshTokenRequestDto,
    RefreshTokenResponseDto,
    RegisterRequestDto,
    RegisterResponseDto,
    RequestTransferTokenRequestDto,
    RequestTransferTokenResponseDto,
    SolveAlgorithmsRequestDto,
    SolveCodeRequestDto,
    SolveComplexityRequestDto,
    SolveTestsRequestDto,
    TooManyRequestsResponseDto,
    UnauthorizedResponseDto,
    UserInfoResponseDto,
    UserMessageRequestDto,
} from '../models/index';
import {
    AnalyticsDashboardIndexResponseDtoFromJSON,
    AnalyticsDashboardIndexResponseDtoToJSON,
    BadRequestResponseDtoFromJSON,
    BadRequestResponseDtoToJSON,
    CreateAffiliateRequestDtoFromJSON,
    CreateAffiliateRequestDtoToJSON,
    CreateAffiliateResponseDtoFromJSON,
    CreateAffiliateResponseDtoToJSON,
    CreateAnalyticsEventRequestDtoFromJSON,
    CreateAnalyticsEventRequestDtoToJSON,
    CreateCheckoutSessionResponseDtoFromJSON,
    CreateCheckoutSessionResponseDtoToJSON,
    IndexResponseDtoFromJSON,
    IndexResponseDtoToJSON,
    InternalServerErrorResponseDtoFromJSON,
    InternalServerErrorResponseDtoToJSON,
    LandingPageStatsResponseDtoFromJSON,
    LandingPageStatsResponseDtoToJSON,
    LinkAffiliateRequestDtoFromJSON,
    LinkAffiliateRequestDtoToJSON,
    LoginRequestDtoFromJSON,
    LoginRequestDtoToJSON,
    LoginResponseDtoFromJSON,
    LoginResponseDtoToJSON,
    LoginTransferTokenRequestDtoFromJSON,
    LoginTransferTokenRequestDtoToJSON,
    LoginTransferTokenResponseDtoFromJSON,
    LoginTransferTokenResponseDtoToJSON,
    RefreshTokenRequestDtoFromJSON,
    RefreshTokenRequestDtoToJSON,
    RefreshTokenResponseDtoFromJSON,
    RefreshTokenResponseDtoToJSON,
    RegisterRequestDtoFromJSON,
    RegisterRequestDtoToJSON,
    RegisterResponseDtoFromJSON,
    RegisterResponseDtoToJSON,
    RequestTransferTokenRequestDtoFromJSON,
    RequestTransferTokenRequestDtoToJSON,
    RequestTransferTokenResponseDtoFromJSON,
    RequestTransferTokenResponseDtoToJSON,
    SolveAlgorithmsRequestDtoFromJSON,
    SolveAlgorithmsRequestDtoToJSON,
    SolveCodeRequestDtoFromJSON,
    SolveCodeRequestDtoToJSON,
    SolveComplexityRequestDtoFromJSON,
    SolveComplexityRequestDtoToJSON,
    SolveTestsRequestDtoFromJSON,
    SolveTestsRequestDtoToJSON,
    TooManyRequestsResponseDtoFromJSON,
    TooManyRequestsResponseDtoToJSON,
    UnauthorizedResponseDtoFromJSON,
    UnauthorizedResponseDtoToJSON,
    UserInfoResponseDtoFromJSON,
    UserInfoResponseDtoToJSON,
    UserMessageRequestDtoFromJSON,
    UserMessageRequestDtoToJSON,
} from '../models/index';

export interface AffiliateCreateV1Request {
    createAffiliateRequestDto: CreateAffiliateRequestDto;
}

export interface AffiliateLinkV1Request {
    linkAffiliateRequestDto: LinkAffiliateRequestDto;
}

export interface AuthLoginTransferTokenV1Request {
    loginTransferTokenRequestDto: LoginTransferTokenRequestDto;
}

export interface AuthLoginV1Request {
    loginRequestDto: LoginRequestDto;
}

export interface AuthRefreshTokenV1Request {
    refreshTokenRequestDto: RefreshTokenRequestDto;
}

export interface AuthRegisterV1Request {
    registerRequestDto: RegisterRequestDto;
}

export interface AuthRequestTransferTokenV1Request {
    requestTransferTokenRequestDto: RequestTransferTokenRequestDto;
}

export interface IndexUserMessageV1Request {
    userMessageRequestDto: UserMessageRequestDto;
}

export interface SolveAlgorithmsV1Request {
    solveAlgorithmsRequestDto: SolveAlgorithmsRequestDto;
}

export interface SolveCodeV1Request {
    solveCodeRequestDto: SolveCodeRequestDto;
}

export interface SolveComplexityV1Request {
    solveComplexityRequestDto: SolveComplexityRequestDto;
}

export interface SolveTestsV1Request {
    solveTestsRequestDto: SolveTestsRequestDto;
}

export interface TreasurerV1Request {
    createAnalyticsEventRequestDto: CreateAnalyticsEventRequestDto;
}

/**
 *
 */
export class BaseApi extends runtime.BaseAPI {
    /**
     */
    async affiliateCreateV1Raw(
        requestParameters: AffiliateCreateV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreateAffiliateResponseDto>> {
        if (requestParameters['createAffiliateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createAffiliateRequestDto',
                'Required parameter "createAffiliateRequestDto" was null or undefined when calling affiliateCreateV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/affiliate/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateAffiliateRequestDtoToJSON(
                    requestParameters['createAffiliateRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreateAffiliateResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async affiliateCreateV1(
        requestParameters: AffiliateCreateV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreateAffiliateResponseDto> {
        const response = await this.affiliateCreateV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async affiliateLinkV1Raw(
        requestParameters: AffiliateLinkV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['linkAffiliateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'linkAffiliateRequestDto',
                'Required parameter "linkAffiliateRequestDto" was null or undefined when calling affiliateLinkV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/affiliate/link`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LinkAffiliateRequestDtoToJSON(requestParameters['linkAffiliateRequestDto']),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async affiliateLinkV1(
        requestParameters: AffiliateLinkV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.affiliateLinkV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async analyticsDashboardIndexV1Raw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<AnalyticsDashboardIndexResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/analytics-dashboard/index`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            AnalyticsDashboardIndexResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async analyticsDashboardIndexV1(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<AnalyticsDashboardIndexResponseDto> {
        const response = await this.analyticsDashboardIndexV1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async analyticsDashboardSubscriptionsV1Raw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/analytics-dashboard/subscriptions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async analyticsDashboardSubscriptionsV1(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<object> {
        const response = await this.analyticsDashboardSubscriptionsV1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authLoginTransferTokenV1Raw(
        requestParameters: AuthLoginTransferTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<LoginTransferTokenResponseDto>> {
        if (requestParameters['loginTransferTokenRequestDto'] == null) {
            throw new runtime.RequiredError(
                'loginTransferTokenRequestDto',
                'Required parameter "loginTransferTokenRequestDto" was null or undefined when calling authLoginTransferTokenV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/auth/login-transfer-token`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginTransferTokenRequestDtoToJSON(
                    requestParameters['loginTransferTokenRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            LoginTransferTokenResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async authLoginTransferTokenV1(
        requestParameters: AuthLoginTransferTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<LoginTransferTokenResponseDto> {
        const response = await this.authLoginTransferTokenV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authLoginV1Raw(
        requestParameters: AuthLoginV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<LoginResponseDto>> {
        if (requestParameters['loginRequestDto'] == null) {
            throw new runtime.RequiredError(
                'loginRequestDto',
                'Required parameter "loginRequestDto" was null or undefined when calling authLoginV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/auth/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginRequestDtoToJSON(requestParameters['loginRequestDto']),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            LoginResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async authLoginV1(
        requestParameters: AuthLoginV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<LoginResponseDto> {
        const response = await this.authLoginV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRefreshTokenV1Raw(
        requestParameters: AuthRefreshTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<RefreshTokenResponseDto>> {
        if (requestParameters['refreshTokenRequestDto'] == null) {
            throw new runtime.RequiredError(
                'refreshTokenRequestDto',
                'Required parameter "refreshTokenRequestDto" was null or undefined when calling authRefreshTokenV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/auth/refresh-token`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RefreshTokenRequestDtoToJSON(requestParameters['refreshTokenRequestDto']),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            RefreshTokenResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async authRefreshTokenV1(
        requestParameters: AuthRefreshTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<RefreshTokenResponseDto> {
        const response = await this.authRefreshTokenV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRegisterV1Raw(
        requestParameters: AuthRegisterV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<RegisterResponseDto>> {
        if (requestParameters['registerRequestDto'] == null) {
            throw new runtime.RequiredError(
                'registerRequestDto',
                'Required parameter "registerRequestDto" was null or undefined when calling authRegisterV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/auth/register`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RegisterRequestDtoToJSON(requestParameters['registerRequestDto']),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            RegisterResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async authRegisterV1(
        requestParameters: AuthRegisterV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<RegisterResponseDto> {
        const response = await this.authRegisterV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequestTransferTokenV1Raw(
        requestParameters: AuthRequestTransferTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<RequestTransferTokenResponseDto>> {
        if (requestParameters['requestTransferTokenRequestDto'] == null) {
            throw new runtime.RequiredError(
                'requestTransferTokenRequestDto',
                'Required parameter "requestTransferTokenRequestDto" was null or undefined when calling authRequestTransferTokenV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/auth/request-transfer-token`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RequestTransferTokenRequestDtoToJSON(
                    requestParameters['requestTransferTokenRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            RequestTransferTokenResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async authRequestTransferTokenV1(
        requestParameters: AuthRequestTransferTokenV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<RequestTransferTokenResponseDto> {
        const response = await this.authRequestTransferTokenV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async healthRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/health`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async health(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.healthRaw(initOverrides);
    }

    /**
     */
    async indexRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<IndexResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            IndexResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async index(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<IndexResponseDto> {
        const response = await this.indexRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async indexLandingPageStatsV1Raw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<LandingPageStatsResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/api/v1/landing-page-stats`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            LandingPageStatsResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async indexLandingPageStatsV1(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<LandingPageStatsResponseDto> {
        const response = await this.indexLandingPageStatsV1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async indexUserMessageV1Raw(
        requestParameters: IndexUserMessageV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userMessageRequestDto'] == null) {
            throw new runtime.RequiredError(
                'userMessageRequestDto',
                'Required parameter "userMessageRequestDto" was null or undefined when calling indexUserMessageV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/user-message`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UserMessageRequestDtoToJSON(requestParameters['userMessageRequestDto']),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async indexUserMessageV1(
        requestParameters: IndexUserMessageV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.indexUserMessageV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async solveAlgorithmsV1Raw(
        requestParameters: SolveAlgorithmsV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['solveAlgorithmsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'solveAlgorithmsRequestDto',
                'Required parameter "solveAlgorithmsRequestDto" was null or undefined when calling solveAlgorithmsV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/solve/algorithms`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SolveAlgorithmsRequestDtoToJSON(
                    requestParameters['solveAlgorithmsRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async solveAlgorithmsV1(
        requestParameters: SolveAlgorithmsV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.solveAlgorithmsV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async solveCodeV1Raw(
        requestParameters: SolveCodeV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['solveCodeRequestDto'] == null) {
            throw new runtime.RequiredError(
                'solveCodeRequestDto',
                'Required parameter "solveCodeRequestDto" was null or undefined when calling solveCodeV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/solve/code`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SolveCodeRequestDtoToJSON(requestParameters['solveCodeRequestDto']),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async solveCodeV1(
        requestParameters: SolveCodeV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.solveCodeV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async solveComplexityV1Raw(
        requestParameters: SolveComplexityV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['solveComplexityRequestDto'] == null) {
            throw new runtime.RequiredError(
                'solveComplexityRequestDto',
                'Required parameter "solveComplexityRequestDto" was null or undefined when calling solveComplexityV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/solve/complexity`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SolveComplexityRequestDtoToJSON(
                    requestParameters['solveComplexityRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async solveComplexityV1(
        requestParameters: SolveComplexityV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.solveComplexityV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async solveTestsV1Raw(
        requestParameters: SolveTestsV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['solveTestsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'solveTestsRequestDto',
                'Required parameter "solveTestsRequestDto" was null or undefined when calling solveTestsV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/solve/tests`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SolveTestsRequestDtoToJSON(requestParameters['solveTestsRequestDto']),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async solveTestsV1(
        requestParameters: SolveTestsV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.solveTestsV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async stripeCreateCheckoutSessionV1Raw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CreateCheckoutSessionResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/stripe/create-checkout-session`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            CreateCheckoutSessionResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async stripeCreateCheckoutSessionV1(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CreateCheckoutSessionResponseDto> {
        const response = await this.stripeCreateCheckoutSessionV1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async treasurerV1Raw(
        requestParameters: TreasurerV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createAnalyticsEventRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createAnalyticsEventRequestDto',
                'Required parameter "createAnalyticsEventRequestDto" was null or undefined when calling treasurerV1().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request(
            {
                path: `/api/v1/treasurer`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateAnalyticsEventRequestDtoToJSON(
                    requestParameters['createAnalyticsEventRequestDto'],
                ),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async treasurerV1(
        requestParameters: TreasurerV1Request,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.treasurerV1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async userInfoV1Raw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserInfoResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearer', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/api/v1/user/info`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserInfoResponseDtoFromJSON(jsonValue),
        );
    }

    /**
     */
    async userInfoV1(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserInfoResponseDto> {
        const response = await this.userInfoV1Raw(initOverrides);
        return await response.value();
    }
}
