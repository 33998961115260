import { useClientStore } from '../../stores/ClientStore.ts';
import { WebSocketService } from '../../ws/WebSocketService.ts';
import { handleApiError } from '../../utils/FormUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { generateCodeStreamId } from '../../ws/id/GenerateCodeStreamId.ts';
import { generateComplexityStreamId } from '../../ws/id/GenerateComplexityStreamId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';

export const requestComplexityOutputAction = async (solveId: string, algorithmId: string) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`requestComplexityOutputAction called from inside web view, ignoring...`);
        return;
    }

    const clientStore = useClientStore();

    const complexityStreamId = generateComplexityStreamId(solveId, algorithmId);
    clientStore.streamsWaiting = [...clientStore.streamsWaiting, complexityStreamId];

    const codeStreamId = generateCodeStreamId(solveId, algorithmId);
    const code = clientStore.streams[codeStreamId];
    if (!code) {
        throw new Error(
            `Unable to request complexity, no code found for streamId (${codeStreamId})`,
        );
    }

    const algorithm = clientStore.solveAlgorithms[algorithmId];
    if (!algorithm) {
        throw new Error(
            `Unable to request complexity, no algorithm found for algorithmId (${algorithmId})`,
        );
    }

    WebSocketService.instance.verifyConnection();

    setTimeout(() => {
        if (
            clientStore.streams[complexityStreamId] === undefined ||
            clientStore.streams[complexityStreamId].length <= 0
        ) {
            clientStore.streamsErrored = [...clientStore.streamsErrored, complexityStreamId];
        }
    }, 30_000); // 30 seconds

    const settingsStore = useSettingsStore();

    try {
        await apiService.getApi().solveComplexityV1({
            solveComplexityRequestDto: {
                code: code,
                algorithmName: algorithm.algorithmName,
                solveId: solveId,
                streamId: complexityStreamId,
                aiModel: settingsStore.activeAiModel ?? AI_MODEL['gpt-4o'],
            },
        });
    } catch (error) {
        await handleApiError(error, undefined, true);

        clientStore.streamsErrored = [...clientStore.streamsErrored, complexityStreamId];

        return false;
    }

    return true;
};
