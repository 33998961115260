import { AuthenticationService } from './AuthenticationService.ts';
import { BaseApi, Configuration } from '../../@generated/api-client';

export const apiService = {
    getApi(): BaseApi {
        const apiConfig = new Configuration({
            basePath: import.meta.env.VITE_API_BASE_URL,
            accessToken: AuthenticationService.instance.getAccessToken() as string, // @TODO
            credentials: 'include',
        });

        return new BaseApi(apiConfig);
    },
};
