import { useClientStore } from '../../stores/ClientStore.ts';
import { WebSocketService } from '../../ws/WebSocketService.ts';
import { handleApiError } from '../../utils/FormUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { generateCodeStreamId } from '../../ws/id/GenerateCodeStreamId.ts';
import { generateTestsStreamId } from '../../ws/id/GenerateTestsStreamId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';

export const requestTestsOutputAction = async (solveId: string, algorithmId: string) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`requestTestsOutputAction called from inside web view, ignoring...`);
        return;
    }

    const clientStore = useClientStore();

    const testsStreamId = generateTestsStreamId(solveId, algorithmId);
    clientStore.streamsWaiting = [...clientStore.streamsWaiting, testsStreamId];

    const codeStreamId = generateCodeStreamId(solveId, algorithmId);
    const code = clientStore.streams[codeStreamId];
    if (!code) {
        throw new Error(`Unable to request tests, no code found for streamId (${codeStreamId})`);
    }

    WebSocketService.instance.verifyConnection();

    setTimeout(() => {
        if (
            clientStore.streams[testsStreamId] === undefined ||
            clientStore.streams[testsStreamId].length <= 0
        ) {
            clientStore.streamsErrored = [...clientStore.streamsErrored, testsStreamId];
        }
    }, 30_000); // 30 seconds

    const settingsStore = useSettingsStore();

    try {
        await apiService.getApi().solveTestsV1({
            solveTestsRequestDto: {
                code: code,
                solveId: solveId,
                streamId: testsStreamId,
                aiModel: settingsStore.activeAiModel ?? AI_MODEL['gpt-4o'],
            },
        });
    } catch (error) {
        await handleApiError(error, undefined, true);

        clientStore.streamsErrored = [...clientStore.streamsErrored, testsStreamId];

        return false;
    }

    return true;
};
