<script setup lang="ts">
import { useSettingsStore } from '../../../stores/SettingsStore.ts';
import { isAccelerator } from '../../../utils/HotkeyUtil.ts';
import { createTextToast } from '../../../utils/ToastUtil.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { eventEmitterUtil } from '../../../utils/EventEmitterUtil.ts';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { TriangleAlert } from 'lucide-vue-next';
import { HOTKEY_LABELS } from '../../../constants/HotkeyLabels.ts';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { unregisterAllHotkeysAction } from '../../../actions/electron/UnregisterAllHotkeysAction.ts';
import { registerHotkeysAction } from '../../../actions/electron/RegisterHotkeysAction.ts';

const settingsStore = useSettingsStore();

interface ComponentState {
    isOpen: boolean;
    isLoading: boolean;
    selectedShortcut: string | number | null;
    currentSelection: string;
    currentSelectionValid: boolean;
}

const state: ComponentState = reactive({
    isOpen: false,
    isLoading: false,
    selectedShortcut: null,
    currentSelection: '',
    currentSelectionValid: false,
});

const openDialog = () => {
    state.isOpen = true;

    unregisterAllHotkeysAction();

    window.addEventListener('keydown', handleKeydown);
};

const closeDialog = () => {
    registerHotkeysAction(JSON.parse(JSON.stringify(settingsStore.hotkeys)));

    state.isOpen = false;

    window.removeEventListener('keydown', handleKeydown);
};

const selectShortcut = (shortcut: string | number | null) => {
    if (shortcut !== null && state.selectedShortcut !== null) {
        return;
    }

    state.selectedShortcut = shortcut;
};

const resetToDefaults = () => {
    state.selectedShortcut = null;
    settingsStore.resetShortcuts();
};

const handleKeydown = (e: KeyboardEvent) => {
    let modifier = null;

    if (e.key === 'Escape') {
        state.selectedShortcut = null;

        return;
    }

    if (e.ctrlKey && e.altKey) {
        modifier = 'AltGr';
    } else if (e.shiftKey) {
        modifier = 'Shift';
    } else if (e.altKey) {
        modifier = 'Alt';
    } else if (e.ctrlKey) {
        modifier = 'CommandOrControl';
    } else if (e.metaKey) {
        modifier = 'CommandOrControl';
    }

    let key = e.key;

    if (!modifier || !key) {
        console.debug('Invalid input:', e);

        return;
    }

    if (e.code.includes('Numpad')) {
        key = e.code;
        key = key.replace('Numpad', 'num');
        key = key.toLowerCase();
    }

    if (e.code.includes('Key')) {
        key = key.toUpperCase();
    }

    switch (key) {
        case 'numdecimal':
            key = 'numdec';
            break;
        case 'numsubtract':
            key = 'numsub';
            break;
        case 'nummultiply':
            key = 'nummult';
            break;
        case 'numdivide':
            key = 'numdiv';
            break;
        case 'NumLock':
            key = 'Numlock';
            break;
    }

    const accelerator = `${modifier}+${key}`;
    state.currentSelection = accelerator;

    const isValid = isAccelerator(accelerator);
    state.currentSelectionValid = isValid;

    console.debug({
        accelerator: accelerator,
        isValid: isValid,
    });

    // Check if already in use
    for (const [shortcut, existingAccelerator] of Object.entries(settingsStore.hotkeys)) {
        if (shortcut !== state.selectedShortcut && accelerator === existingAccelerator) {
            createTextToast(
                'This key combination is already in use.',
                'Please try a different combination.',
                'destructive',
                false,
            );

            return;
        }
    }

    if (isValid && state.selectedShortcut !== null) {
        settingsStore.hotkeys = {
            ...settingsStore.hotkeys,
            [state.selectedShortcut]: accelerator,
        };
        state.selectedShortcut = null;
    }
};

onMounted(() => {
    eventEmitterUtil.addListener('openShortcutConfigurationDialog', openDialog);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('openShortcutConfigurationDialog', openDialog);
});
</script>

<template>
    <Dialog v-model:open="state.isOpen">
        <DialogContent
            :disable-outside-pointer-events="true"
            :hide-close-button="true"
            @escape-key-down="(e: Event) => e.preventDefault()"
            @close-auto-focus="(e: Event) => e.preventDefault()"
            @focus-outside="(e: Event) => e.preventDefault()"
            @interact-outside="(e: Event) => e.preventDefault()"
        >
            <form>
                <DialogHeader class="mb-4">
                    <DialogTitle>Hotkey configuration</DialogTitle>
                </DialogHeader>

                <p class="my-4 text-sm">
                    A valid hotkey is a combination of a modifier and a key. See
                    <a
                        href="https://www.electronjs.org/docs/latest/api/accelerator#available-modifiers"
                        target="_blank"
                        class="underline"
                        >the following page</a
                    >
                    for all available combinations. Single keys can not be used as hotkeys due to
                    system limitations.
                </p>

                <div
                    v-if="state.selectedShortcut !== null"
                    class="mb-4 text-sm font-bold text-muted-foreground"
                >
                    <TriangleAlert class="me-1 inline h-4 w-4 text-yellow-600" />
                    Setting hotkey for action: "{{
                        (HOTKEY_LABELS as any)[state.selectedShortcut]
                    }}", waiting for valid input...
                </div>

                <div class="wrapper">
                    <div v-for="(accelerator, action) in settingsStore.hotkeys" :key="action">
                        <Label>{{ (HOTKEY_LABELS as any)[action] }}</Label>
                        <Input
                            type="text"
                            :placeholder="
                                state.selectedShortcut === action
                                    ? 'Waiting for valid input...'
                                    : accelerator
                            "
                            class="mt-2 hover:cursor-pointer focus-visible:ring-0"
                            :class="{ 'border-white': state.selectedShortcut === action }"
                            readonly
                            @click="selectShortcut(action)"
                        />
                    </div>
                </div>

                <DialogFooter class="mt-6">
                    <div class="flex w-full justify-between">
                        <div>
                            <Button
                                type="button"
                                :disabled="state.isLoading || state.selectedShortcut === null"
                                variant="destructive"
                                class="me-2"
                            >
                                <span v-if="!state.isLoading" @click="selectShortcut(null)"
                                    >Cancel</span
                                >
                            </Button>
                            <Button
                                type="button"
                                :disabled="state.isLoading"
                                variant="secondary"
                                @click="resetToDefaults"
                            >
                                <span v-if="!state.isLoading" @click="selectShortcut(null)"
                                    >Reset to defaults</span
                                >
                            </Button>
                        </div>
                        <Button type="button" :disabled="state.isLoading">
                            <span v-if="!state.isLoading" @click="closeDialog">Save and exit</span>
                        </Button>
                    </div>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>

<style scoped>
.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}
</style>
