// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnalyticsDashboardIndexResponseDto
 */
export interface AnalyticsDashboardIndexResponseDto {
    /**
     *
     * @type {Array<string>}
     * @memberof AnalyticsDashboardIndexResponseDto
     */
    dates: Array<string>;
    /**
     *
     * @type {Array<number>}
     * @memberof AnalyticsDashboardIndexResponseDto
     */
    users: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof AnalyticsDashboardIndexResponseDto
     */
    subscriptions: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof AnalyticsDashboardIndexResponseDto
     */
    sessions: Array<number>;
}

/**
 * Check if a given object implements the AnalyticsDashboardIndexResponseDto interface.
 */
export function instanceOfAnalyticsDashboardIndexResponseDto(
    value: object,
): value is AnalyticsDashboardIndexResponseDto {
    if (!('dates' in value) || value['dates'] === undefined) return false;
    if (!('users' in value) || value['users'] === undefined) return false;
    if (!('subscriptions' in value) || value['subscriptions'] === undefined) return false;
    if (!('sessions' in value) || value['sessions'] === undefined) return false;
    return true;
}

export function AnalyticsDashboardIndexResponseDtoFromJSON(
    json: any,
): AnalyticsDashboardIndexResponseDto {
    return AnalyticsDashboardIndexResponseDtoFromJSONTyped(json, false);
}

export function AnalyticsDashboardIndexResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AnalyticsDashboardIndexResponseDto {
    if (json == null) {
        return json;
    }
    return {
        dates: json['dates'],
        users: json['users'],
        subscriptions: json['subscriptions'],
        sessions: json['sessions'],
    };
}

export function AnalyticsDashboardIndexResponseDtoToJSON(
    value?: AnalyticsDashboardIndexResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        dates: value['dates'],
        users: value['users'],
        subscriptions: value['subscriptions'],
        sessions: value['sessions'],
    };
}
