import { handleInputSourceCaptureResponseAction } from './actions/solve/SolveAction.ts';
import { InputSource } from './types/electron/InputSource.ts';
import { handleRequestSourcesResponseAction } from './actions/electron/handlers/HandleRequestSourcesResponseAction.ts';
import { Hotkey } from './types/Hotkey.ts';
import { useUiStore } from './stores/UiStore.ts';
import { registerRouterSyncHook } from './router.ts';
import { registerClientStoreWatcher } from './stores/ClientStore.ts';
import { handleRequestSourcesIdListResponseAction } from './actions/electron/handlers/HandleRequestSourcesIdListResponseAction.ts';
import { handleRequestDesktopAppVersionResponseAction } from './actions/electron/handlers/HandleRequestDesktopAppVersionResponseAction.ts';
import { handleShortcutPressAction } from './actions/app/HandleShortcutPressAction.ts';
import { refreshTokenAction } from './actions/auth/RefreshTokenAction.ts';
import { registerSettingsStoreWatcher, useSettingsStore } from './stores/SettingsStore.ts';
import { requestSourcesAction } from './actions/electron/RequestSourcesAction.ts';
import { requestDesktopAppVersionAction } from './actions/electron/RequestDesktopAppVersionAction.ts';
import { eventEmitterUtil } from './utils/EventEmitterUtil.ts';
import { WebSocketService } from './ws/WebSocketService.ts';
import { AI_MODEL } from './constants/AiModel.ts';

export const bootstrapDesktopClient = () => {
    runStoreMigrations();

    const uiStore = useUiStore();
    uiStore.isInsideWebView = false;

    window.electronApi.onScreenshotResponse((screenshotData: string, referenceId: string) => {
        void handleInputSourceCaptureResponseAction(screenshotData, referenceId);
    });

    window.electronApi.onRequestSourcesResponse((sources: InputSource[]) => {
        void handleRequestSourcesResponseAction(sources);
    });

    window.electronApi.onRequestSourcesIdListResponse((sourceIds) => {
        void handleRequestSourcesIdListResponseAction(sourceIds);
    });

    window.electronApi.onRequestDesktopAppVersionResponse((version: string) => {
        void handleRequestDesktopAppVersionResponseAction(version);
    });

    window.electronApi.onShortcutPress(async (hotkey: Hotkey) => {
        void handleShortcutPressAction(hotkey);
    });

    window.electronApi.onRequestPermissionStatusResponse(async (status: { screen: boolean }) => {
        eventEmitterUtil.emit('onRequestPermissionStatusResponse', status);
    });

    // Request input sources
    requestSourcesAction();

    // Request desktop version string
    requestDesktopAppVersionAction();

    // Register a hook to listen for route changes to sync with web view
    registerRouterSyncHook();

    // Register subscribers to listen for store mutations to sync with web view
    registerClientStoreWatcher();
    registerSettingsStoreWatcher();

    // Start interval to fetch input source ID's every 3 seconds
    setInterval(() => {
        window.electronApi.requestSourcesIdList();
    }, 3000);

    // Start interval to refresh auth token
    setInterval(
        () => {
            void refreshTokenAction();
        },
        5 * 60 * 1000,
    ); // 5 minutes (token is valid for 15 minutes)

    // Verify WebSocket connection
    setInterval(() => {
        void WebSocketService.instance.verifyConnection();
    }, 2.5 * 1000); // 2.5 seconds
};

export const bootstrapWebView = () => {
    runStoreMigrations();

    const uiStore = useUiStore();
    uiStore.isInsideWebView = true;

    // Start interval to refresh auth token
    setInterval(
        () => {
            void refreshTokenAction();
        },
        5 * 60 * 1000,
    ); // 5 minutes (token is valid for 15 minutes)

    // Verify WebSocket connection
    setInterval(() => {
        void WebSocketService.instance.verifyConnection();
    }, 2.5 * 1000); // 2.5 seconds
};

const runStoreMigrations = () => {
    const settingsStore = useSettingsStore();

    if (!settingsStore.activeAiModel) {
        settingsStore.activeAiModel = AI_MODEL['gpt-4o'];
    }
};
