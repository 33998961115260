// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SolveCodeRequestDto
 */
export interface SolveCodeRequestDto {
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    imageData: string;
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    algorithmName: string;
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    programmingLanguage: string;
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    solveId: string;
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    streamId: string;
    /**
     *
     * @type {string}
     * @memberof SolveCodeRequestDto
     */
    aiModel: SolveCodeRequestDtoAiModelEnum;
}

/**
 * @export
 */
export const SolveCodeRequestDtoAiModelEnum = {
    Gpt4o: 'gpt-4o',
    Claude35Sonnet: 'claude-35-sonnet',
    OpenaiO1: 'openai-o1',
} as const;
export type SolveCodeRequestDtoAiModelEnum =
    (typeof SolveCodeRequestDtoAiModelEnum)[keyof typeof SolveCodeRequestDtoAiModelEnum];

/**
 * Check if a given object implements the SolveCodeRequestDto interface.
 */
export function instanceOfSolveCodeRequestDto(value: object): value is SolveCodeRequestDto {
    if (!('imageData' in value) || value['imageData'] === undefined) return false;
    if (!('algorithmName' in value) || value['algorithmName'] === undefined) return false;
    if (!('programmingLanguage' in value) || value['programmingLanguage'] === undefined)
        return false;
    if (!('solveId' in value) || value['solveId'] === undefined) return false;
    if (!('streamId' in value) || value['streamId'] === undefined) return false;
    if (!('aiModel' in value) || value['aiModel'] === undefined) return false;
    return true;
}

export function SolveCodeRequestDtoFromJSON(json: any): SolveCodeRequestDto {
    return SolveCodeRequestDtoFromJSONTyped(json, false);
}

export function SolveCodeRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SolveCodeRequestDto {
    if (json == null) {
        return json;
    }
    return {
        imageData: json['imageData'],
        algorithmName: json['algorithmName'],
        programmingLanguage: json['programmingLanguage'],
        solveId: json['solveId'],
        streamId: json['streamId'],
        aiModel: json['aiModel'],
    };
}

export function SolveCodeRequestDtoToJSON(value?: SolveCodeRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        imageData: value['imageData'],
        algorithmName: value['algorithmName'],
        programmingLanguage: value['programmingLanguage'],
        solveId: value['solveId'],
        streamId: value['streamId'],
        aiModel: value['aiModel'],
    };
}
