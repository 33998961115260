<script setup lang="ts">
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ROUTES } from '../../router.ts';
import { Card, CardContent } from '../ui/card';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../ui/breadcrumb';
import { House } from 'lucide-vue-next';
import HotkeyIndicator from '../hotkey/HotkeyIndicator.vue';
import { Hotkey } from '../../types/Hotkey.ts';
import { useClientStore } from '../../stores/ClientStore.ts';
import { useUiStore } from '../../stores/UiStore.ts';

const router = useRouter();
const route = useRoute();

const clientStore = useClientStore();
const settingsStore = useSettingsStore();
const uiStore = useUiStore();

const enum AppPage {
    Start = 0,
    OutputAlgoSelection = 1,
    OutputFinal = 2,
}

const navigateToIndexPage = () => {
    if (uiStore.isInsideWebView) {
        return;
    }

    router.push({
        name: ROUTES.index,
    });
};

const navigateToAlgorithmSelectionPage = () => {
    if (uiStore.isInsideWebView) {
        return;
    }

    if (!clientStore.activeSolveId) {
        return;
    }

    router.push({
        name: ROUTES.solveAlgorithmSelection,
        params: {
            solveId: String(clientStore.activeSolveId),
        },
    });
};

const navigateToOutputPage = () => {
    if (uiStore.isInsideWebView) {
        return;
    }

    if (!clientStore.activeSolveId || !clientStore.solveAlgorithms) {
        return;
    }

    router.push({
        name: ROUTES.solveOutput,
        params: {
            solveId: String(clientStore.activeSolveId),
            algorithmId: String(clientStore.activeAlgorithmId),
        },
    });
};

const showOutputAlgoSelectionNav = computed(() => {
    if (!clientStore.activeSolveId || settingsStore.isOnboarding) {
        return false;
    }

    return true;
});

const selectedAlgorithm = computed(() => {
    if (!clientStore.activeAlgorithmId) {
        return undefined;
    }

    return clientStore.solveAlgorithms[clientStore.activeAlgorithmId];
});

const selectedNavigationAlgorithm = computed((): number => {
    return clientStore.navigationVerticalIndexes[String(clientStore.activeSolveId)];
});

const currentPageIndex = computed(() => {
    if (route.name === ROUTES.index) {
        return AppPage.Start;
    } else if (route.name === ROUTES.solveAlgorithmSelection) {
        return AppPage.OutputAlgoSelection;
    } else {
        return AppPage.OutputFinal;
    }
});
</script>

<template>
    <Card
        class="select-none whitespace-nowrap rounded-none border-b border-t-0 dark:border-gray-800 dark:bg-gray-900"
    >
        <CardContent class="flex h-full items-center justify-between py-0 pe-0 ps-2">
            <Breadcrumb>
                <BreadcrumbList class="flex-nowrap">
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            v-if="currentPageIndex !== AppPage.Start"
                            class="flex items-center justify-center text-muted-foreground hover:cursor-pointer"
                            :class="{ 'hover:cursor-not-allowed': uiStore.isInsideWebView }"
                            @click="navigateToIndexPage"
                        >
                            <House class="me-2 h-4 w-4" />
                            Menu
                            <HotkeyIndicator
                                v-if="currentPageIndex - 1 === AppPage.Start"
                                :hotkey="Hotkey.Left"
                                class="ms-2"
                            />
                        </BreadcrumbLink>
                        <BreadcrumbPage
                            v-else
                            class="flex items-center justify-center"
                            :class="{
                                'dark:text-gray-100': clientStore.activeSolveId,
                                'hover:cursor-not-allowed': uiStore.isInsideWebView,
                            }"
                        >
                            <House class="me-2 h-4 w-4" />
                            Menu
                            <HotkeyIndicator
                                v-if="currentPageIndex - 1 === AppPage.Start"
                                :hotkey="Hotkey.Left"
                                class="ms-2"
                            />
                        </BreadcrumbPage>
                    </BreadcrumbItem>

                    <BreadcrumbSeparator v-if="showOutputAlgoSelectionNav" />

                    <BreadcrumbItem v-if="showOutputAlgoSelectionNav">
                        <BreadcrumbLink
                            v-if="currentPageIndex !== AppPage.OutputAlgoSelection"
                            class="flex items-center justify-center text-muted-foreground hover:cursor-pointer"
                            :class="{ 'hover:cursor-not-allowed': uiStore.isInsideWebView }"
                            @click="navigateToAlgorithmSelectionPage"
                        >
                            <HotkeyIndicator
                                v-if="currentPageIndex + 1 === AppPage.OutputAlgoSelection"
                                :hotkey="Hotkey.Right"
                                class="me-2"
                            />
                            Algorithms
                            <HotkeyIndicator
                                v-if="currentPageIndex - 1 === AppPage.OutputAlgoSelection"
                                :hotkey="Hotkey.Left"
                                class="ms-2"
                            />
                        </BreadcrumbLink>
                        <BreadcrumbPage
                            v-else
                            class="flex items-center justify-center"
                            :class="{ 'hover:cursor-not-allowed': uiStore.isInsideWebView }"
                        >
                            <HotkeyIndicator
                                v-if="currentPageIndex + 1 === AppPage.OutputAlgoSelection"
                                :hotkey="Hotkey.Right"
                                class="me-2"
                            />
                            Algorithms
                            <HotkeyIndicator
                                v-if="currentPageIndex - 1 === AppPage.OutputAlgoSelection"
                                :hotkey="Hotkey.Left"
                                class="ms-2"
                            />
                        </BreadcrumbPage>
                    </BreadcrumbItem>

                    <BreadcrumbSeparator v-if="clientStore.activeAlgorithmId" />

                    <BreadcrumbItem v-if="clientStore.activeAlgorithmId">
                        <BreadcrumbLink
                            v-if="currentPageIndex !== AppPage.OutputFinal"
                            class="flex items-center justify-center text-muted-foreground hover:cursor-pointer"
                            :class="{ 'hover:cursor-not-allowed': uiStore.isInsideWebView }"
                            @click="navigateToOutputPage"
                        >
                            <HotkeyIndicator
                                v-if="
                                    currentPageIndex + 1 === AppPage.OutputFinal &&
                                    selectedAlgorithm &&
                                    selectedAlgorithm.index === selectedNavigationAlgorithm
                                "
                                :hotkey="Hotkey.Right"
                                class="me-2"
                            />
                            {{ selectedAlgorithm?.algorithmName }}
                        </BreadcrumbLink>
                        <BreadcrumbPage
                            v-else
                            :class="{ 'hover:cursor-not-allowed': uiStore.isInsideWebView }"
                        >
                            <HotkeyIndicator
                                v-if="
                                    currentPageIndex + 1 === AppPage.OutputFinal &&
                                    selectedAlgorithm &&
                                    selectedAlgorithm.index === selectedNavigationAlgorithm
                                "
                                :hotkey="Hotkey.Right"
                                class="me-2"
                            />
                            {{ selectedAlgorithm?.algorithmName }}
                        </BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>
        </CardContent>
    </Card>
</template>
